@import "global";
body {
  font-family: 'Montserrat', serif;
  --color-background: white;
  --color-card: white;
  --color-text: black;
  --color-icon: #aeaeb7;
  --color-card-text: black;
  --color-active-text: #e2f1fd;
  --background: #f4f4f9;
  --background-item: #DDF6EB;
  --background-btn: #40C067;
  --calendar-background: #EBF8F0;
  --color-btn: white;
  --color-list-item: #40C067;
}
body.dark {
  --color-background: #0D0D0D;
  --color-card: #0D0D0D;
  --color-card-text: white;
  --color-active-text: #353636;
  background-color: black;
  --calendar-background: #353636;
  --background: #151515;
  --color-icon: white;
  --color-btn: #40C067;
  --color-text: #D8DDD9;
  --background-item: #0E3625;
  --background-btn: #0E3625;
  --color-list-item: #22C97B;
}
.apexcharts-tooltip {
  background: var(--background) !important;
  .apexcharts-tooltip-title {
    background-color: var(--background) !important;
  }
}
.website-footer {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
  padding: 55px 0 40px;
  margin-top: -20px;
  z-index: 5;
}
@media (max-width : 767px) {
  .website-footer {
    padding: 30px 0 15px;
    margin-top: -20px;
    z-index: 4;
  }
}
.website-footer .footerbg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #0a2333;
}
@media (max-width : 767px) {
  .website-footer .footerbg {
    background-color: #0a2333;
  }
}
.website-footer .footerbg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.website-footer .sect-content {
  position: relative;
  z-index: 3;
}
.website-footer .footer-main-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding-bottom: 20px;
}
@media (max-width : 767px) {
  .website-footer .footer-main-row {
    display: block;
  }
}
.website-footer .footer-main-row .left-col {
  width: 100%;
}
@media (max-width : 767px) {
  .website-footer .footer-main-row .left-col {
    width: 100%;
  }
}
.website-footer .footer-main-row .left-col .footer-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-left: 25px;
}
@media (min-width:1400px) {
  .website-footer .footer-main-row .left-col .footer-nav {
    padding-left: 50px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .website-footer .footer-main-row .left-col .footer-nav {
    padding-left: 0;
  }
}
@media (max-width : 767px) {
  .website-footer .footer-main-row .left-col .footer-nav {
    padding-left: 20px;
  }
}
.website-footer .footer-main-row .left-col .footer-nav li {
  width: 50%;
}
.website-footer .footer-main-row .right-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.website-footer .nav-title {
  display: block;
  font-size: 23px;
  font-weight: 500;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 45px;
}
@media (min-width:992px) and (max-width:1199px) {
  .website-footer .nav-title {
    font-size: 26px;
    margin-bottom: 30px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .website-footer .nav-title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }
}
@media (max-width : 767px) {
  .website-footer .nav-title {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 22px;
  }
}
.website-footer .footer-nav {
  margin-bottom: 50px;
  //padding-left: 25px;
}
@media (min-width:768px) and (max-width:991px) {
  .website-footer .footer-nav {
    padding-left: 0;
  }
}
@media (max-width : 767px) {
  .website-footer .footer-nav {
    margin-bottom: 20px;
    padding-left: 0px;
  }
  .website-footer .footer-nav li:first-child a {
    padding-top: 0;
  }
}
.website-footer .footer-nav a {
  color: #C9CCE3;
  font-size: 20px;
  font-weight: normal;
  padding: 3px 0;
  display: inline-block;
}
@media (min-width:992px) and (max-width:1199px) {
  .website-footer .footer-nav a {
    font-size: 19px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .website-footer .footer-nav a {
    font-size: 18px;
  }
}
@media (max-width : 767px) {
  .website-footer .footer-nav a {
    font-size: 14px;
  }
}
.website-footer .footer-nav a:hover {
  color: #fff;
}
.website-footer .right-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  max-width: 750px;
}
@media (max-width : 767px) {
  .website-footer .right-col {
    display: block;
    width: 100%;
    gap: 10px;
    font-size: 12px;
  }
  .website-footer .footer-main-row .right-col {
    flex-wrap: wrap;
  }
  .website-footer .right-col .nav-col {
    width: 48%;
  }
  .website-footer .right-col .nav-col .footer-nav:last-child {
    margin-bottom: 0;
  }
}
.website-footer .soclist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 76px;
}
@media (min-width:992px) and (max-width:1199px) {
  .website-footer .soclist {
    margin-bottom: 50px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .website-footer .soclist {
    margin-bottom: 50px;
  }
}
@media (max-width : 767px) {
  .website-footer .soclist {
    margin-bottom: 10px;
  }
}
.website-footer .soclist li {
  padding-right: 16px;
}
@media (max-width : 767px) {
  .website-footer .soclist li {
    padding-right: 10px;
  }
}
.website-footer .soclist li:last-child {
  padding-right: 0;
}
.website-footer .soclist a {
  border: 1px solid rgba(103, 117, 247, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 64px;
  height: 55px;
  min-width: 65px;
  border-radius: 9px;
}
.website-footer .soclist a:hover {
  border-color: rgba(221, 221, 221, 0.68);
}
@media (min-width:768px) and (max-width:991px) {
  .website-footer .soclist a {
    width: 60px;
    min-width: 60px;
  }
}
@media (max-width : 767px) {
  .website-footer .soclist a {
    width: 45px;
    min-width: 45px;
    height: 42px;
  }
}
.website-footer .footer-bottom-row {
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width:768px) and (max-width:991px) {
  .website-footer .footer-bottom-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-width : 767px) {
  .website-footer .footer-bottom-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding-top: 15px;
  }
}
.website-footer .copy {
  display: block;
  font-size: 16px;
  left: 21px;
  font-weight: 500;
  color: #fff;
}
@media (max-width : 767px) {
  .website-footer .copy {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .website-footer .bottom-left-column {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    padding-top: 5px;
  }
}
@media (max-width : 767px) {
  .website-footer .bottom-left-column {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
}
.website-footer .footer-bottom-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width : 767px) {
  .website-footer .footer-bottom-nav {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }
}
.website-footer .footer-bottom-nav li {
  margin-right: 35px;
}
@media (min-width:992px) and (max-width:1199px) {
  .website-footer .footer-bottom-nav li {
    margin-right: 20px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .website-footer .footer-bottom-nav li {
    margin-right: 15px;
  }
}
@media (max-width : 767px) {
  .website-footer .footer-bottom-nav li {
    margin: 0 0 5px;
  }
}
.website-footer .footer-bottom-nav li:last-child {
  margin-right: 0;
}
.website-footer .footer-bottom-nav a {
  font-size: 21px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.6);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width:992px) and (max-width:1199px) {
  .website-footer .footer-bottom-nav a {
    font-size: 18px;
    line-height: 18px;
  }
}
@media (max-width : 767px) {
  .website-footer .footer-bottom-nav a {
    font-size: 15px;
    font-weight: normal;
  }
}
.website-footer .footer-bottom-nav a:hover {
  color: #fff;
}

body {
  background-color: white;
}

html.modal-open,
html.modal-open {
  overflow-x: hidden;
}

body.modal-open {
  position: relative;
  overflow: hidden;
}

.pop-block {
  display: none;
  position: absolute;
}

.modal.fade.show {
  opacity: 1;
}


._g-simple-modal .closeModal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(19, 54, 108, 0.25);
}
._g-simple-modal .modal-dialog {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transform: none !important;
  transform: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 900px;
}
._g-simple-modal .modal-content {
  background: #ffffff;
  -webkit-box-shadow: 40px -6px 184px -20px rgba(170, 184, 213, 0.4);
  box-shadow: 40px -6px 184px -20px rgba(170, 184, 213, 0.4);
  border-radius: 20px;
  max-width: 1050px;
  width: 80%;
  border: none;
  min-width: 760px;
  padding: 35px 3.5% 55px;
}
._g-simple-modal .modal-heading-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 35px;
}
._g-simple-modal .modal-heading {
  font-size: 50px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: -2px;
  display: block;
}

.index-top-slider {
  width: 100%;
  position: relative;
  max-height: 950px;
  min-height: 750px;
  background: #fff;
}
@media (min-width:768px) and (max-width:991px) {
  .index-top-slider {
    height: 100vh;
    max-height: 750px;
  }
}
@media (max-width : 767px) {
  .index-top-slider {
    height: 100vh;
    min-height: 400px;
    max-height: 600px;
  }
  ._g-simple-modal .modal-heading {
    font-size: 30px;
  }
}
.index-top-slider:after {
  content: "";
  display: block;
  clear: both;
  padding-bottom: 56.6%;
}
.index-top-slider .slider-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}
.index-top-slider .swiper {
  height: 100%;
  width: 100%;
}
.index-top-slider .swiper-slide {
  width: 100%;
  height: 100%;
  position: relative;
}
.index-top-slider .slidebg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.index-top-slider .slidebg picture {
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
  display: block;
  height: 100%;
  width: 100%;
}
.index-top-slider .slidebg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.index-top-slider .slidebg .slide-video-bg {
  width: 100%;
  height: 100%;
}
.index-top-slider .slidebg .slide-video-bg ._g-loader {
  opacity: 1;
  visibility: visible;
}
.index-top-slider .slidebg .slide-video-bg video {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.index-top-slider .slidebg .slide-video-bg.loadedVideo ._g-loader {
  opacity: 0;
  visibility: hidden;
}
.index-top-slider .slidebg .slide-video-bg.loadedVideo video {
  opacity: 1;
}
.index-top-slider .swiper-abs-arrows {
  position: absolute;
  top: 130px;
  left: 0;
  z-index: 5;
  padding-left: 3vw;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .index-top-slider .swiper-abs-arrows {
    padding-left: 4vw;
  }
}
@media (min-width:1908px) {
  .index-top-slider .swiper-abs-arrows {
    left: 50%;
    margin-left: -900px;
    padding-left: 0;
  }
}
@media (max-width : 767px) {
  .index-top-slider .swiper-abs-arrows {
    top: auto;
    bottom: 55px;
    width: 100%;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.index-top-slider .swiper-abs-arrows .arrows-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 3vw;
}
@media (min-width:1400px) {
  .index-top-slider .swiper-abs-arrows .arrows-holder {
    padding-left: 2vw;
    padding-right: 2vw;
  }
}
@media (min-width:1908px) {
  .index-top-slider .swiper-abs-arrows .arrows-holder {
    padding-top: 40px;
  }
}
.index-top-slider .swiper-abs-arrows button {
  position: static;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background: #DCE7FD;
  width: 54px;
  height: 54px;
  margin: 0 0 0 8px;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
@media (max-width : 767px) {
  .index-top-slider .swiper-abs-arrows button {
    width: 30px;
    height: 30px;
    background: #fff;
  }
  .index-top-slider .swiper-abs-arrows button img {
    max-width: 15px;
  }
}
.index-top-slider .swiper-abs-arrows button:first-child {
  margin-left: 0;
}
.index-top-slider .swiper-abs-arrows button:after {
  display: none;
}
.index-top-slider .swiper-abs-arrows button:hover {
  background: #396cf3;
}
.index-top-slider .swiper-abs-arrows button:hover img {
  -webkit-filter: grayscale(1) brightness(5);
  filter: grayscale(1) brightness(5);
}
.index-top-slider .swiper-abs-arrows button img {
  -webkit-transition: -webkit-filter 0.3s ease;
  transition: -webkit-filter 0.3s ease;
  transition: filter 0.3s ease;
  transition: filter 0.3s ease, -webkit-filter 0.3s ease;
}
.index-top-slider .swiper-abs-arrows button.swiper-button-prev img {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.index-top-slider .slide-content {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  padding-top: 130px;
  padding-bottom: 3%;
}
@media (max-width : 767px) {
  .index-top-slider .slide-content {
    padding-top: 90px;
  }
}
.index-top-slider .slide-content:before {
  content: "";
  display: block;
  clear: both;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60%;
  z-index: 4;
  background: -webkit-gradient(linear, left top, right top, from(#F8FAFD), to(rgba(248, 250, 253, 0)));
  background: linear-gradient(to right, #F8FAFD 0%, rgba(248, 250, 253, 0) 100%);
}
@media (min-width:768px) and (max-width:991px) {
  .index-top-slider .slide-content:before {
    width: 70%;
  }
}
.index-top-slider .slide-content .container {
  height: 100%;
  padding-bottom: 60px;
  position: relative;
  z-index: 4;
}
.index-top-slider .slide-content .slide-content-holder {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding-top: 3vw;
}
@media (min-width:1400px) {
  .index-top-slider .slide-content .slide-content-holder {
    padding-left: 2vw;
    padding-right: 2vw;
  }
}
@media (min-width:1908px) {
  .index-top-slider .slide-content .slide-content-holder {
    padding-top: 68px;
  }
}
.index-top-slider .slide-content .content-bottom .blue-circle-btn {
  margin-left: -10px;
  background-color: #0a2333;
  span {
    white-space: pre-line;
    text-align: center;
  }
}
.index-top-slider .slide-content .content-top {
  max-width: 650px;
  width: 38%;
  padding-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
@media (min-width:992px) and (max-width:1199px) {
  .index-top-slider .slide-content .content-top {
    width: 41%;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .index-top-slider .slide-content .content-top {
    width: 50%;
  }
}
@media (max-width : 767px) {
  .index-top-slider .slide-content .content-top {
    width: 100%;
    padding-top: 0;
    display: block;
  }
}
.index-top-slider .slide-content .slide-title {
  display: block;
  font-size: 3.7vw;
  line-height: 3vw;
  font-weight: 500;
  margin-bottom: 35px;
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
@media (min-width:1908px) {
  .index-top-slider .slide-content .slide-title {
    font-size: 70px;
    line-height: 57px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .index-top-slider .slide-content .slide-title {
    font-size: 4.7vw;
    line-height: 4vw;
  }
}
@media (max-width : 767px) {
  .index-top-slider .slide-content .slide-title {
    font-size: 30px;
    line-height: 27px;
    color: white;
  }
}
.index-top-slider .slide-content p {
  font-size: 23px;
  line-height: 23px;
  color: #9DABCF;
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
@media (min-width:992px) and (max-width:1199px) {
  .index-top-slider .slide-content p {
    font-size: 21px;
    line-height: 21px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .index-top-slider .slide-content p {
    font-size: 19px;
    line-height: 21px;
  }
}
@media (max-width : 767px) {
  .index-top-slider .slide-content p {
    color: #9DABCF;
    font-size: 15px;
    line-height: 18fpx;
    max-width: 200px;
    font-weight: 300;
  }
}
.index-top-slider .slide-content .blue-circle-btn {
  border-radius: 50%;
  width: 160px;
  height: 160px;
  background: #396CF3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  line-height: 18px;
  color: #fff;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
@media (min-width:768px) and (max-width:991px) {
  .index-top-slider .slide-content .blue-circle-btn {
    width: 130px;
    height: 130px;
  }
}
@media (max-width : 767px) {
  .index-top-slider .slide-content .blue-circle-btn {
    width: 100px;
    height: 100px;
    font-size: 14px;
    line-height: 13px;
  }
}
.index-top-slider .swiper-initialized .slide-bg picture {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.index-top-slider .swiper-initialized .slide-content .slide-title {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.index-top-slider .swiper-initialized .slide-content p {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.index-top-slider .swiper-initialized .slide-content .blue-circle-btn {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.index-top-slider .slider-bottom-box {
  position: absolute;
  bottom: 6%;
  left: 0;
  width: 100%;
  z-index: 4;
}
@media (max-width : 767px) {
  .index-top-slider .slider-bottom-box {
    bottom: 30px;
  }
}
.index-top-slider .slider-bottom-box .bottom-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
@media (max-width : 767px) {
  .index-top-slider .slider-bottom-box .bottom-row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
  }
}
.index-top-slider .slider-bottom-box .bottom-row .left-col {
  padding-left: 40px;
}
@media (max-width : 767px) {
  .index-top-slider .slider-bottom-box .bottom-row .left-col {
    padding-left: 0;
  }
}
@media (min-width:1400px) {
  .index-top-slider .slider-bottom-box .bottom-row .left-col {
    padding-left: 2vw;
    padding-right: 2vw;
  }
}
@media (max-width : 767px) {
  .index-top-slider .slider-bottom-box .bottom-row .right-col {
    display: none;
  }
}
.index-top-slider .slider-bottom-box .swiper-pagination {
  position: static;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 30px !important;
  left: 0;
}
.index-top-slider .slider-bottom-box .swiper-pagination .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: rgba(138, 142, 174, 0.4);
  opacity: 1;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.index-top-slider .slider-bottom-box .swiper-pagination .swiper-pagination-bullet-active {
  background: #396cf3;
}
.index-top-slider .slider-bottom-box .soc-links-title {
  display: block;
  font-size: 17px;
  line-height: 15px;
  color: #9EC0E1;
  margin-bottom: 15px;
}
.index-top-slider .slider-bottom-box .soc-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.index-top-slider .slider-bottom-box .soc-links a {
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-right: 18px;
  background: #9FC0E1;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.index-top-slider .slider-bottom-box .soc-links a:hover {
  background: #396cf3;
}

.index-coins-section {
  background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), color-stop(10%, #FFFFFF), color-stop(50%, #E8F2FD), to(#FAFCFF));
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 10%, #E8F2FD 50%, #FAFCFF 100%);
  border-radius: 20px 20px 0 0;
  margin-top: -20px;
  position: relative;
  z-index: 3;
}
@media (max-width : 767px) {
  .index-coins-section {
    border-radius: 30px;
  }
}
@media (max-width : 767px) {
  .index-coins-section .coins-short-list {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  .index-coins-section .coins-short-list .coin-item:nth-last-child(-n+2) {
    display: none;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .index-coins-section .coins-short-list .coin-item:nth-last-child(-n+1) {
    display: none;
  }
}

.coins-sectbox {
  padding: 50px 0 70px;
  position: relative;
  z-index: 4;
}
@media (max-width : 767px) {
  .coins-sectbox {
    padding: 20px 0 30px;
  }
}
.coins-sectbox .coins-heading-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}
@media (max-width : 767px) {
  .coins-sectbox .coins-heading-row {
    display: block;
    margin-bottom: 20px;
  }
}
.coins-sectbox .coins-heading-row .titles-col {
  padding-right: 30px;
}
@media (max-width : 767px) {
  .coins-sectbox .coins-heading-row .titles-col {
    padding-right: 0;
    padding-bottom: 20px;
  }
}
.coins-sectbox .coins-heading-row .search-col {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 60%;
}
@media (max-width : 767px) {
  .coins-sectbox .coins-heading-row .search-col {
    width: 100%;
    max-width: none;
  }
}
.coins-sectbox .coins-heading-row h2 {
  display: block;
  font-weight: 500;
  font-size: 80px;
  line-height: 72px;
  margin: 0 0 10px -5px;
}
@media (max-width : 767px) {
  .coins-sectbox .coins-heading-row h2 {
    font-size: 30px;
    line-height: 30px;
    margin-left: 0;
    margin-bottom: 5px;
  }
}
.coins-sectbox .coins-heading-row .subtitle {
  display: block;
  color: #A7ABBE;
  font-weight: 300;
  font-size: 26px;
  line-height: 24px;
}
@media (max-width : 767px) {
  .coins-sectbox .coins-heading-row .subtitle {
    font-size: 15px;
    line-height: 16px;
    font-weight: 300;
  }
}

.faq-page-sectbox {
  position: relative;
  z-index: 3;
  // background: url("../shared/images/@2x/policyBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0 80px;
  border-radius: 0 0 20px 20px;
  .container {
    padding: 20px;
    border-radius: 16px;
    background-color: var(--color-card);
    p, span,i,li, div {
      color: var(--color-card-text);
    }
    h3 {
      text-align: center;
    }
  }
}
@media (min-width:768px) and (max-width:991px) {
  .faq-page-sectbox {
    padding-top: 50px;
  }
}
@media (max-width : 767px) {
  .faq-page-sectbox {
    padding: 30px 30px;
    border-radius: 0 0 20px 20px;
    margin-bottom: -20px;
  }
  .faq-page-sectbox + .feats-banners-sectbox {
    padding-top: 50px;
  }
}
.faq-page-sectbox h1 {
  font-size: 100px;
  font-weight: 500;
  line-height: 90px;
  letter-spacing: -3px;
  margin: 0 0 50px;
}
@media (min-width:768px) and (max-width:991px) {
  .faq-page-sectbox h1 {
    font-size: 80px;
    margin-bottom: 30px;
  }
}
@media (max-width : 767px) {
  .faq-page-sectbox h1 {
    font-size: 30px;
    line-height: 27px;
    letter-spacing: -1px;
    margin-bottom: 20px;
  }
}
.faq-page-sectbox .faq-content-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 50px;
}
@media (max-width : 767px) {
  .faq-page-sectbox .faq-content-row {
    display: block;
    margin-bottom: 0;
  }
}
.faq-page-sectbox .faq-content-row .content-column {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  padding-top: 61px;
  padding-left: 3%;
}
@media (min-width:768px) and (max-width:991px) {
  .faq-page-sectbox .faq-content-row .content-column {
    padding-top: 0;
    padding-left: 10%;
  }
}
@media (max-width : 767px) {
  .faq-page-sectbox .faq-content-row .content-column {
    padding-top: 0;
    padding-left: 0;
  }
}
.faq-page-sectbox .faq-sidebar {
  width: 250px;
  position: relative;
}
@media (min-width:768px) and (max-width:991px) {
  .faq-page-sectbox .faq-sidebar {
    display: none;
  }
}
@media (max-width : 767px) {
  .faq-page-sectbox .faq-sidebar {
    display: none;
  }
}
.faq-page-sectbox .faq-sidebar .nav-fix-box {
  padding-top: 20px;
}
.faq-page-sectbox .faq-sidebar .side-title {
  display: block;
  font-size: 18px;
  color: #7A82A1;
  font-weight: 300;
  margin-bottom: 25px;
  padding-left: 15px;
}
.faq-page-sectbox .faq-sidebar .faq-cat-nav li a {
  border-radius: 14px;
  display: block;
  padding: 11px 15px 14px;
  color: #060D45;
  font-size: 32px;
  line-height: 32px;
}
.faq-page-sectbox .faq-sidebar .faq-cat-nav li a:hover {
  color: #396cf3;
}
.faq-page-sectbox .faq-sidebar .faq-cat-nav li.active a {
  background: #396cf3;
  color: #fff;
}
.faq-page-sectbox .faq-part {
  padding: 20px 0 50px;
}
@media (max-width : 767px) {
  .faq-page-sectbox .faq-part {
    padding: 10px 0 20px;
  }
}
.faq-page-sectbox .faq-part:first-child {
  padding-top: 0;
}
.faq-page-sectbox .faq-part:last-child {
  padding-bottom: 0;
}
.faq-page-sectbox h2 {
  font-size: 70px;
  line-height: 70px;
  letter-spacing: -2px;
  font-weight: normal;
  margin: 0 0 10px;
}
@media (min-width:768px) and (max-width:991px) {
  .faq-page-sectbox h2 {
    font-size: 50px;
    line-height: 50px;
  }
}
@media (max-width : 767px) {
  .faq-page-sectbox h2 {
    font-size: 28px;
    line-height: 28px;
  }
}
.faq-page-sectbox h3 {
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -2px;
  font-weight: normal;
  margin: 0 0 10px;
}
@media (min-width:768px) and (max-width:991px) {
  .faq-page-sectbox h3 {
    font-size: 30px;
    line-height: 30px;
  }
}
@media (max-width : 767px) {
  .faq-page-sectbox h3 {
    font-size: 21px;
    line-height: 21px;
  }
}
.faq-page-sectbox .panel-group .panel {
  border-bottom: 1px solid rgba(138, 142, 174, 0.6);
}
@media (max-width : 767px) {
  .faq-page-sectbox .panel-group .panel {
    border-bottom: 1px solid rgba(138, 142, 174, 0.3);
  }
}
.faq-page-sectbox .panel-group .panel-body .textbox {
  padding: 10px 30px 25px 47px;
}
@media (max-width : 767px) {
  .faq-page-sectbox .panel-group .panel-body .textbox {
    padding: 0 0 20px 30px;
  }
}
.faq-page-sectbox .panel-group .panel-body p {
  color: #535883;
  font-weight: 300;
  font-size: 18px;
  line-height: 19px;
  margin: 0 0 25px;
}
@media (max-width : 767px) {
  .faq-page-sectbox .panel-group .panel-body p {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 15px;
  }
  .faq-page-sectbox .panel-group .panel-body p:last-child {
    margin-bottom: 0;
  }
}
.faq-page-sectbox .acc-link-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.faq-page-sectbox .acc-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 22px 50px 25px 0;
  font-size: 35px;
  line-height: 31px;
  font-weight: 300;
  color: #060D45;
  position: relative;
}
@media (min-width:768px) and (max-width:991px) {
  .faq-page-sectbox .acc-link {
    font-size: 28px;
    padding: 15px 50px 13px 0;
  }
}
@media (max-width : 767px) {
  .faq-page-sectbox .acc-link {
    font-size: 18px;
    padding: 9px 0 12px 0;
    line-height: 18px;
    color: #396cf3;
  }
}
@media (max-width : 767px) {
  .faq-page-sectbox .acc-link.collapsed {
    color: #060D45;
  }
}
.faq-page-sectbox .acc-link.collapsed i {
  background: none;
  border-color: #E2E2E2;
}
@media (min-width: 768px) {
  .faq-page-sectbox .acc-link:hover {
    color: #396CF3;
  }
}
.faq-page-sectbox .acc-link i {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  width: 26px;
  height: 26px;
  min-width: 26px;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-right: 20px;
}
@media (max-width : 767px) {
  .faq-page-sectbox .acc-link i {
    width: 12px;
    height: 12px;
    min-width: 12px;
    margin-right: 15px;
  }
}
.faq-page-sectbox .acc-link i:before {
  content: "";
  display: block;
  clear: both;
  position: absolute;
  height: 3px;
  top: 50%;
  margin-top: -2px;
  left: 0;
  width: 100%;
  background: #396CF3;
  border-radius: 3px;
}
@media (max-width : 767px) {
  .faq-page-sectbox .acc-link i:before {
    margin-top: -1px;
    height: 2px;
  }
}
.faq-page-sectbox .acc-link i:after {
  content: "";
  display: block;
  clear: both;
  position: absolute;
  width: 3px;
  margin-left: -2px;
  left: 50%;
  top: 0;
  height: 100%;
  background: #396CF3;
  border-radius: 3px;
}
@media (max-width : 767px) {
  .faq-page-sectbox .acc-link i:after {
    width: 2px;
    margin-left: -1px;
  }
}

.simple-page-sectbox {
  position: relative;
  z-index: 3;
  background: linear-gradient(190deg, #C4D7FC 0%, #FFFFFF 600px, #fff 100%);
  padding: 170px 0 80px;
  border-radius: 0 0 20px 20px;
  min-height: 80vh;
}
@media (min-width:768px) and (max-width:991px) {
  .simple-page-sectbox {
    padding-top: 140px;
  }
}
@media (max-width : 767px) {
  .simple-page-sectbox {
    padding: 100px 0 20px;
    border-radius: 0 0 20px 20px;
    background: linear-gradient(190deg, #ECF4FE 0%, #FFFFFF 600px, #fff 100%);
    margin-bottom: -20px;
  }
}
.simple-page-sectbox .content-part {
  padding: 0px 0 50px;
}
@media (max-width : 767px) {
  .simple-page-sectbox .content-part {
    padding: 0px 0 20px;
  }
}
.simple-page-sectbox .content-part:first-child {
  padding-top: 0;
}
.simple-page-sectbox .content-part:last-child {
  padding-bottom: 0;
}
.simple-page-sectbox h1 {
  font-size: 100px;
  font-weight: 500;
  line-height: 90px;
  letter-spacing: -3px;
  margin: 0 0 40px;
}
@media (min-width:768px) and (max-width:991px) {
  .simple-page-sectbox h1 {
    font-size: 80px;
    margin-bottom: 30px;
  }
}
@media (max-width : 767px) {
  .simple-page-sectbox h1 {
    font-size: 30px;
    line-height: 27px;
    letter-spacing: -1px;
    margin-bottom: 20px;
  }
}
.simple-page-sectbox h2 {
  font-size: 70px;
  line-height: 70px;
  letter-spacing: -2px;
  font-weight: normal;
  margin: 0 0 20px;
}
@media (min-width:768px) and (max-width:991px) {
  .simple-page-sectbox h2 {
    font-size: 50px;
    line-height: 50px;
  }
}
@media (max-width : 767px) {
  .simple-page-sectbox h2 {
    font-size: 28px;
    line-height: 28px;
  }
}
.simple-page-sectbox h3 {
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -2px;
  font-weight: normal;
  margin: 0 0 10px;
}
@media (min-width:768px) and (max-width:991px) {
  .simple-page-sectbox h3 {
    font-size: 30px;
    line-height: 30px;
  }
}
@media (max-width : 767px) {
  .simple-page-sectbox h3 {
    font-size: 21px;
    line-height: 21px;
  }
}
.simple-page-sectbox p {
  margin: 0 0 25px;
  font-size: 18px;
}

.coin-currency-sectbox {
  background: linear-gradient(136deg, #4C83EA 10%, #C2D6FB 60%, #DBE8FF 100%);
  padding: 85px 0 90px;
  position: relative;
}
@media (min-width:768px) and (max-width:991px) {
  .coin-currency-sectbox {
    padding-top: 50px;
  }
}
@media (max-width : 767px) {
  .coin-currency-sectbox {
    margin-top: -20px;
    border-radius: 20px 20px 0 0;
    padding: 30px 0 35px;
    background: linear-gradient(136deg, #578AEC 10%, #6C99EF 60%, #B6CFFA 100%);
  }
}
.coin-currency-sectbox + .connect-worker-sectbox {
  border-radius: 30px;
}
.coin-currency-sectbox .currency-main-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 80px;
}
@media (min-width:768px) and (max-width:991px) {
  .coin-currency-sectbox .currency-main-form {
    margin-bottom: 50px;
  }
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-main-form {
    display: block;
    margin-bottom: 20px;
    position: relative;
  }
}
.coin-currency-sectbox .currency-main-form .left-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 3%;
  border-right: 1px solid #0404040d;
}
@media (min-width:768px) and (max-width:991px) {
  .coin-currency-sectbox .currency-main-form .left-col {
    display: block;
    position: relative;
  }
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-main-form .left-col {
    padding: 0 0 40px;
    border: none;
    width: 100%;
  }
}
.coin-currency-sectbox .currency-main-form .coin-icon {
  width: 80px;
  height: 80px;
  min-width: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width:768px) and (max-width:991px) {
  .coin-currency-sectbox .currency-main-form .coin-icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-main-form .coin-icon {
    position: absolute;
    left: 0;
    width: 60px;
    min-width: 60px;
    height: 60px;
  }
}
.coin-currency-sectbox .currency-main-form .coin-icon img {
  width: 100%;
}
.coin-currency-sectbox .currency-main-form .coin-main-desc {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 30px;
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-main-form .coin-main-desc {
    padding-left: 0;
  }
}
.coin-currency-sectbox .currency-main-form .right-col {
  padding-left: 30px;
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-main-form .right-col {
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.coin-currency-sectbox .currency-main-form .right-col ._g-radio-custom-row {
  margin-bottom: 35px;
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-main-form .right-col ._g-radio-custom-row {
    margin-bottom: 15px;
  }
}
.coin-currency-sectbox .currency-site-box {
  padding-left: 25px;
}
@media (min-width:768px) and (max-width:991px) {
  .coin-currency-sectbox .currency-site-box {
    padding-left: 0;
  }
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-site-box {
    padding-left: 0;
  }
}
.coin-currency-sectbox .currency-site-box span {
  display: block;
  color: #060D45;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: -1px;
  margin-bottom: 15px;
  white-space: nowrap;
  font-weight: 500;
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-site-box span {
    color: #eee;
    margin-bottom: 5px;
  }
}
.coin-currency-sectbox .currency-site-box em {
  font-style: normal;
  display: block;
  font-size: 16px;
  line-height: 14px;
  color: #95A2B8;
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-site-box em {
    color: #fefefe;
    font-weight: 300;
    font-size: 14px;
  }
}
@media (max-width : 767px) {
  .coin-currency-sectbox ._g-radio-custom-row {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: auto;
  }
  .coin-currency-sectbox ._g-radio-custom-row .radio-item {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }
  .coin-currency-sectbox ._g-radio-custom-row .radio-holder {
    color: #fff;
  }
}
.coin-currency-sectbox .currency-choice-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 75px;
}
@media (min-width:768px) and (max-width:991px) {
  .coin-currency-sectbox .currency-choice-row {
    display: block;
    margin-bottom: 20px;
    padding-left: 70px;
  }
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-choice-row {
    display: block;
    margin-bottom: 15px;
  }
}
.coin-currency-sectbox .currency-choice-row .name-col {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 30px;
  color: #fff;
}
@media (min-width:768px) and (max-width:991px) {
  .coin-currency-sectbox .currency-choice-row .name-col {
    padding-right: 10px;
    margin-bottom: 30px;
  }
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-choice-row .name-col {
    padding: 5px 0 20px 75px;
    min-height: 90px;
  }
}
.coin-currency-sectbox .currency-choice-row .valutes-col {
  min-width: 280px;
}
@media (min-width:768px) and (max-width:991px) {
  .coin-currency-sectbox .currency-choice-row .valutes-col {
    width: 280px;
  }
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-choice-row .valutes-col {
    min-width: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.coin-currency-sectbox .currency-choice-row .name {
  display: block;
  font-size: 57px;
  line-height: 51px;
  letter-spacing: -2px;
  margin-bottom: 13px;
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-choice-row .name {
    font-size: 30px;
    line-height: 27px;
    margin-bottom: 5px;
  }
}
.coin-currency-sectbox .currency-choice-row .subtitle {
  display: block;
  font-weight: 300;
  font-size: 24px;
  line-height: 22px;
}
@media (max-width : 767px) {
  .coin-currency-sectbox .currency-choice-row .subtitle {
    font-size: 14px;
    line-height: 12px;
  }
}
.coin-currency-sectbox .showed-currency-box {
  color: #ffffff;
  font-size: 5vw;
  letter-spacing: -0.1vw;
  font-weight: 500;
  white-space: nowrap;
}
@media (min-width:1908px) {
  .coin-currency-sectbox .showed-currency-box {
    font-size: 100px;
    line-height: 100px;
    letter-spacing: -3px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .coin-currency-sectbox .showed-currency-box {
    font-size: 4vw;
  }
}
@media (max-width : 767px) {
  .coin-currency-sectbox .showed-currency-box {
    font-size: 27px;
    margin-bottom: 5px;
  }
}
.coin-currency-sectbox ._g-warn-box {
  padding-left: 0;
}
.coin-currency-sectbox ._g-warn-box i {
  border-color: #fff;
  width: 20px;
  height: 20px;
  color: #fff;
}
@media (max-width : 767px) {
  .coin-currency-sectbox ._g-warn-box i {
    width: 14px;
    min-width: 14px;
    height: 14px;
    font-size: 12px;
    border-width: 1px;
    font-weight: 500;
    padding-bottom: 0;
  }
}
.coin-currency-sectbox ._g-warn-box span {
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}
@media (max-width : 767px) {
  .coin-currency-sectbox ._g-warn-box span {
    padding-left: 10px;
    padding-top: 0;
    font-size: 13px;
    line-height: 14px;
  }
}
