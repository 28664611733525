@import "src/styles/variables";
.expandedContent {
  padding: 10px;
  margin-left: 70px;
  &__title {
    font-size: map-get($font-size, regular);
    font-weight: 600;
  }
  &__table {
    font-size: map-get($font-size, small);
    margin-top: 15px;
    thead {
      th {
        font-weight: 600;
        padding: 5px 10px 5px 0;
      }
    }
    tbody {
      td {
        padding: 5px 10px 5px 0;
      }
    }
  }
}